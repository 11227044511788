import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"
import BackgroundImg from "gatsby-background-image"
import { FaPhone, FaUtensils } from "react-icons/fa"

import Title from "../../banners/SectionTitle"

const AppointmentComponent = () => {
  const allfiles1 = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          phone
          phoneSeparate
        }
      }

      allContentfulRezerviraj {
        nodes {
          mainTitle
          text {
            text
          }
          mainImage {
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          firstSubtitle
          text2 {
            text2
          }
          sliki {
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          secondSubtitle
          text3 {
            text3
          }
          slikiForThirdSubmenu {
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  `)

  const contentfulData = allfiles1.allContentfulRezerviraj.nodes[0]
  return (
    <AppointmentWrapper>
      <BackgroundImg
        heigth="100%"
        role="img"
        fluid={contentfulData.mainImage.fluid}
      >
        <article className="appointment-card-content main align-items-center">
          {/* <h3>Резервирај маса</h3> */}
          <Title title={contentfulData.mainTitle} icon={<FaUtensils />} />

          <p className="main-p pb-4">{contentfulData.text.text}</p>
          <a
            className="custombtn"
            href={`tel:${allfiles1.site.siteMetadata.phone}`}
          >
            Закажи маса
            <FaPhone
              style={{
                fontSize: "1.52rem",
                marginLeft: "5px",
                verticalAlign: "middle",
              }}
            />
          </a>
        </article>
      </BackgroundImg>

      <div className="card-wrapper">
        <article className="appointment-card">
          <BackgroundImg
            heigth="100%"
            role="img"
            fluid={contentfulData.sliki[0].fluid}
          >
            <div className="appointment-card-content">
              <Title
                title={contentfulData.firstSubtitle}
                icon={<FaUtensils />}
              />
              <p>{contentfulData.text2.text2}</p>
            </div>
          </BackgroundImg>

          <div className="appointment-card-image-wrapper">
            <Img fluid={contentfulData.sliki[1].fluid} alt="terasa" />
          </div>
        </article>

        <article className="appointment-card">
          <BackgroundImg
            heigth="100%"
            role="img"
            fluid={contentfulData.slikiForThirdSubmenu[0].fluid}
          >
            <div className="appointment-card-content">
              <Title
                title={contentfulData.secondSubtitle}
                icon={<FaUtensils />}
              />

              <p>{contentfulData.text3.text3}</p>
            </div>
          </BackgroundImg>

          <div className="appointment-card-image-wrapper">
            <Img
              fluid={contentfulData.slikiForThirdSubmenu[1].fluid}
              alt="terasa"
            />
          </div>
        </article>
      </div>
    </AppointmentWrapper>
  )
}

const AppointmentWrapper = styled.section`
border-top: 5px solid var(--maincolor);
  * {
    margin: 0;
    padding: 0;
  }

  .appointment-card {
  }

  .appointment-card-content {
    text-align: center;
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    align-items: stretch ;
    justify-content: center;
    height: calc(50vh - var(--navbarheight) / 2);
min-height: 400px;
background: linear-gradient(
        100deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0.6) 100%
      ),
      linear-gradient(100deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
    text-shadow: 4px 4px 10px rgba(0, 0, 1, 0.9);

    &.main {
min-height: 550px;
      background: linear-gradient(
          100deg,
          rgba(0, 0, 0, 0.2) 0%,
          rgba(0, 0, 0, 0.6) 100%
        ),
        linear-gradient(100deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
        p {
          font-size: 1.1rem;
        }
    }

 

    h3 {
      color: white;
    }

    p {
      margin: 0 auto;
      margin-top: 40px;
      width: 95%;
      color: var(--hovercolor);
      font-size: 1.1rem;
      letter-spacing: 0.1rem;
    }
  }

  .appointment-card-image-wrapper {
    
    height: calc(50vh - var(--navbarheight) / 2);
    min-height: 400px;
  }

  .gatsby-image-wrapper {
    background-attachment: fixed;
    background-size: cover;
    background-position: 60% 50%;
    background-repeat: repeat;
    width: 100%;
    min-height: 100%;
  }

  @media (min-width: 800px) {
    .appointment-card {
      display: grid;
      grid-template-columns: 1fr 1fr;

      &:last-child {
        direction: rtl;
      }
    }

    .appointment-card-content p {
      width: 75%;
    }


    .main-p {
      margin: 0 auto;
     
    }
    }
  }
`

export default AppointmentComponent
