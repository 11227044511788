import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import { Carousel } from 'react-bootstrap';
import Img from "gatsby-image"
import styled from 'styled-components'

const CarouselComponent = ({ className }) => {
  const allfiles = useStaticQuery(graphql`
    query {
     carousel: allContentfulCarousal {
    edges {
      node {
        title
        slika {
          fluid(maxWidth:1920, quality: 100) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }

    }
  `)
  const CarouselDataContentful = allfiles.carousel.edges;
  return (
    <section className={className}>
      <Carousel
        showArrows="true"
        controls="true"
        justify-self="end"
        align-self="center"
        control-prev-icon-color="invert(100%)"
        control-next-icon-color="invert(100%)"
        className='carousel-component'>
        {CarouselDataContentful.map(singleCarousel => {
          const CarouselTitle = singleCarousel.node.title
          return (
            <Carousel.Item className="carousel-item" key={singleCarousel.node.title}>
              <Img
                backgroundColor="black"
                objectFit="cover"
                objectPosition="50% 50%"
                fluid={singleCarousel.node.slika.fluid} alt={singleCarousel.node.title} />
              <Carousel.Caption className='carousel-caption d-none mx-auto  d-md-block'>

                <h4 className="py-2">{CarouselTitle.toUpperCase()}</h4>
              </Carousel.Caption>
            </Carousel.Item>
          )
        })
        }
      </Carousel>
    </section>
  )
};

export default styled(CarouselComponent)`
.gatsby-image-wrapper {
  height: 100vh;
}

.gatsby-image-wrapper:before {
  content:'';
position: absolute;
top:0;
bottom:0;
right:0;
left:0;
z-index:1;
background: linear-gradient(100deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.6) 100% ),
linear-gradient(100deg, rgba(0,0,0,0.2) 0%, transparent 100% );
}


.carousel-component .carousel-indicators li {
    background-color: var(--maincolor);
    width: 5px;
    height: 5px;
    border-radius: 100%;
}

.carousel-component .carousel-caption h4 {
    color: var(--secondarycolor);
    /* background-color: rgba(132, 36, 12, 0.3); */
    font-size: 1.5rem;
    animation-name: example;
    animation-duration: 2s;
    display: inline-block;
    padding: 0 1rem;
}
@keyframes example {
    0% {
        transform: translateY(-1000%);
    }

    100% {
        transform: translateY(0);
    }
}

@media (max-width: 800px) {
    /* .carousel-component .carousel-caption h4 {
        font-size: 1.1rem;
    } */
    .gatsby-image-wrapper {
  height: 60vh;
}
}

.carousel-component .carousel-caption h3 {
    transform: translateX(0);
}


@media (max-width: 799px) {
  padding-top: var(--navbarheight);
  }

`;
