import React from "react"
import styled from "styled-components"

const SectionTitle = ({ title, icon, className }) => (
  <article className={className}>
    <h3 className="fancy">
      {title}
      <br />
      <span>{icon}</span>
    </h3>
  </article>
)

export default styled(SectionTitle)`

  .fancy {
    text-transform: uppercase;
    color: white;

    line-height: 0;
    text-align: center;
  }
  .fancy p {
    font-size: 1.4rem;
  }
  .fancy span {
    margin-top: 30px;
    opacity: 0.4;
    display: inline-block;
    position: relative;
  }
  .fancy span:before,
  .fancy span:after {
    content: "";
    position: absolute;
    height: 0.2rem;
    border-bottom: 2px solid white;
    border-top: 3px solid white;
    top: 35%;
    width: 120px;
  }
  .fancy span:before {
    right: 100%;
    margin-right: 1rem;
  }
  .fancy span:after {
    left: 100%;
    margin-left: 1rem;
  }
`
