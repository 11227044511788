import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { IconContext } from "react-icons"
import { FaInstagram } from "react-icons/fa"
import styled from "styled-components"
import Img from "gatsby-image"

import LikeImg from "../../../images/like.png"
const Instagram = () => {
  const data = useStaticQuery(graphql`
    query {
      allInstaNode(limit: 8, sort: { fields: [timestamp], order: [DESC] }) {
        edges {
          node {
            id
            likes
            comments
            mediaType
            preview
            original
            timestamp
            localFile {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  `)
  const instagramPosts = data.allInstaNode.edges
  return (
    <InstagramWrapper>
      <p className="instagram-banner">
        <IconContext.Provider value={{ className: "react-icons" }}>
          <FaInstagram />
        </IconContext.Provider>
        restorantkancona
      </p>
      <article>
        {instagramPosts.map(({ node }) => {
          return (
            <div className="image-wrapper" key={node.id}>
              <Img fluid={node.localFile.childImageSharp.fluid} />
              <div className="overlay">
                <img className="icon" src={LikeImg} alt="like icon" />
                <p className="text">{node.likes}</p>
              </div>
            </div>
          )
        })}
      </article>
    </InstagramWrapper>
  )
}
const InstagramWrapper = styled.section`
  border-top: 5px solid var(--maincolor);

  .instagram-banner {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: inline-block;
    margin: 0;
    z-index: 9;
    background: var(--maincolor);
    color: white;
    padding: 0.51rem;
    text-shadow: 4px 4px 5px rgba(0, 0, 0, 0.5);
    border-radius: 0 0 5px 5px;
  }

  .image-wrapper {
    margin: 0;
    padding: 0;
    height: 35vh;
    width: 100%;
    position: relative;
    .overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 100%;
      opacity: 0;
      transition: 0.5s ease-out;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      .icon {
        /* align-self: center; */
        height: 25px;
        color: #fff;
      }
      .text {
        color: #fff;
        background: none;
        font-size: 25px;
        padding: 0 10px;
      }
    }
  }

  .image-wrapper:hover .overlay {
    opacity: 0.8;
  }

  .gatsby-image-wrapper {
    background-attachment: fixed;
    background-size: cover;
    background-position: 60% 50%;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    border-left: 1px solid var(--maincolor);
    border-right: 1px solid var(--maincolor);
    margin: 0;
    padding: 0;
  }

  @media (min-width: 450px) {
    article {
      display: grid;
      grid-template-columns: 1fr 1fr;
      /* grid-template-rows */
    }
  }

  @media (min-width: 800px) {
    .image-wrapper {
      height: 35vh;
    }
  }

  @media (min-width: 1170px) {
    article {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      /* grid-template-rows */
    }

    .image-wrapper {
      height: 45vh;
    }
  }

  .react-icons {
    vertical-align: middle;
    margin-bottom: 3px;
    box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.2);
    margin-right: 5px;
    border-radius: 3px;
    background: #f09433;
    background: -moz-linear-gradient(
      45deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%
    );
    background: -webkit-linear-gradient(
      45deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%
    );
    background: linear-gradient(
      45deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
  }
`

export default Instagram
