import React from "react"
import styled from "styled-components"
import Title from "../../banners/ServicesTitle"
import { Container, Row, Col } from "react-bootstrap"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import { GrRestaurant } from "react-icons/gr"

const AboutUS = () => {
  const ContentData = useStaticQuery(graphql`
    query {
      contentful: allContentfulPageSettings {
        nodes {
          homeAboutTitle
          homeAboutText1 {
            homeAboutText1
          }
          homeAboutText2 {
            homeAboutText2
          }
          homeAboutImages {
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  `)
  const data = ContentData.contentful.nodes[0]
  return (
    <AboutUSWrapper>
      <div className="py-5">
        <Title maincolor title={data.homeAboutTitle} icon={<GrRestaurant />} />
      </div>
      <Container>
        <Row className="align-items-center mb-5 pr-4">
          <Col md="5" className="mb-3">
            <p>{data.homeAboutText1.homeAboutText1}</p>
          </Col>
          <Col md="6">
            <Img
              className="backgroundsection"
              fluid={data.homeAboutImages[0].fluid}
            />
          </Col>
        </Row>
        <Row className="my-5" className="align-items-center  pr-4">
          <Col md="5" md={{ order: 2 }} className="mb-3">
            <p>{data.homeAboutText2.homeAboutText2}</p>
          </Col>

          <Col md="6">
            <Img
              className="backgroundsection"
              fluid={data.homeAboutImages[1].fluid}
            />
          </Col>
        </Row>
      </Container>
    </AboutUSWrapper>
  )
}
const AboutUSWrapper = styled.section`
  padding: 4rem 0 6rem;
  border-top: 5px solid var(--maincolor);

  .backgroundsection {
    width: 100%;
    max-width: 350px;
    height: auto;
    margin: 0 auto;
    filter: drop-shadow(5px 5px 5px #222);
  }
`
export default AboutUS
