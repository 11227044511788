import React from "react"
import styled from "styled-components"
import { IconContext } from "react-icons"

const SectionTitle = ({ title, icon, className, text, maincolor }) => (
  <article className={className}>
    <h3 className="fancy">
      {title}
      <br />
      <span>
        <IconContext.Provider value={{ className: "react-icons" }}>
          {icon}
        </IconContext.Provider>
      </span>
    </h3>
    <p>{text}</p>
  </article>
)

export default styled(SectionTitle)`
  .fancy {
    text-shadow: 3px 2px 8px rgba(0,0,0,0.5);
    text-transform: uppercase;
    color: ${props => (props.maincolor ? "var(--maincolor)" : "white")};
    line-height: 0;
    text-align: center;
    letter-spacing: 0.2rem;
    font-size: ${props => (props.maincolor ? "2rem" : "")};;
  }
  p {
    margin: 15px auto 0;
    width: 95%;
    color: var(--hovercolor);
    font-size: 1.32rem;
    letter-spacing: 0.1rem;
  }
  .fancy span {
    margin-top: 30px;
    opacity: 0.6;
    display: inline-block;
    position: relative;
  }
  .react-icons {
  }

  .fancy span:before,
  .fancy span:after {
    content: "";
    position: absolute;
    height: 0.2rem;
    border-bottom: 2px solid
      ${props => (props.maincolor ? "var(--mainblack)" : "white")};
    border-top: 3px solid
      ${props => (props.maincolor ? "var(--mainblack)" : "white")};
    top: 35%;
    width: 120px;
  }
  .fancy span:before {
    right: 100%;
    margin-right: 1rem;
  }
  .fancy span:after {
    left: 100%;
    margin-left: 1rem;
  }
`
