import React from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"

const Paralax = ({ imgSrc }) => {
  return (
    <ParalaxWrapper>
      <BackgroundImage
        role="img"
        fluid={imgSrc}
        fadeIn={"soft"}
        className="parallax"
        style={{
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
          backgroundPosition: "60% 50%",
          backgroundRepeat: "no-repeat",
        }}
      />
    </ParalaxWrapper>
  )
}

const ParalaxWrapper = styled.section`
  border-top: 5px solid var(--maincolor);
  .parallax {
    height: 200px;
  }

  @media (min-width: 800px) {
    .parallax {
      height: 400px;
    }
  }

  .gatsby-image-wrapper {
    height: 100vh;
  }
`

export default Paralax
