import React from "react"
import { graphql } from "gatsby"
import Carousel from "../components/home/carousel/carousel.component"
import AboutUs from "../components/home/aboutus/Aboutus"
import Paralax from "../components/home/parallax/Paralax"
import Modals from "../components/home/modalsection/Modals"
import Appointment from "../components/home/appointment/Appointment"
import Instagram from "../components/home/instagram/Instagram"

const IndexPage = ({ data }) => {
  return (
    <>
      <Carousel />
      <AboutUs />
      <Paralax
        imgSrc={data.allContentfulPageSettings.nodes[0].parallaxImages[0].fluid}
      />
      <Modals />
      <Appointment />
      <Paralax
        imgSrc={data.allContentfulPageSettings.nodes[0].parallaxImages[1].fluid}
      />
      <Instagram />
    </>
  )
}

export default IndexPage

export const query = graphql`
  query {
    allContentfulPageSettings {
      nodes {
        parallaxImages {
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`
