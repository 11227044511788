import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Modal, Container, Row, Col, Card, Button } from "react-bootstrap"
import styled from "styled-components"
import Img from "gatsby-image"

const ModalCard = ({
  imgSrc,
  title,
  text,
  modalTitle,
  modalText1,
  modalText2,
  modalImages,
}) => {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  return (
    <>
      <Col
        xs={10}
        md={5}
        lg={3}
        className=" mb-4 mx-auto d-flex align-items-stretch"
      >
        <CardWrapper className="d-flex align-items-stretch m-0 p-0">
          <Card
            style={{ background: "#84240C" }}
            text="light"
            className=" m-0 p-0"
          >
            <Card.Header as="h2">
              <Card.Title className="m-0 text-left text-uppercase">
                {title}
              </Card.Title>
            </Card.Header>
            <div className="overflow">
              <Img fluid={imgSrc} />
            </div>
            <Card.Body>
              <Card.Text className="my-4 text-light flex-fill">
                {text}
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <Button
                style={{ color: "var(--maincolor)" }}
                variant="light"
                onClick={handleShow}
              >
                Види повеќе
              </Button>
            </Card.Footer>
          </Card>
        </CardWrapper>
      </Col>

      <Modal
      style={{height:'90vh',marginTop: '25px'}}
      scrollable={true}
        centered
        aria-labelledby="contained-modal-title-vcenter"
        show={show}
        onHide={handleClose}
        animation={true}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {modalTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalText1}
          <ModalImageWrapper>
            {modalImages.map((image, ix) => (
              <Img key={`image-${ix}`} fluid={image.fluid} />
            ))}
          </ModalImageWrapper>

          {modalText2}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Затвори
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
const CardWrapper = styled.article`
  .card {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;

    &:before,
    &:after {
      content: "";
      position: absolute;
      z-index: -1;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
      top: 50%;
      bottom: 0;
      left: 10px;
      right: 10px;
      border-radius: 100px / 10px;
    }

    h2 {
      margin: 0;
    }
  }
`

const ModalImageWrapper = styled.div`

  margin: 20px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
`

const Modals = ({ className }) => {
  const allfiles1 = useStaticQuery(graphql`
    query {
      allContentfulModalCards {
        edges {
          node {
            id
            cardTitle
            cardText {
              cardText
            }
            cardImage {
              fluid {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            modalTitle
            modalText1 {
              modalText1
            }
            modalText2 {
              modalText2
            }
            modalImages {
              fluid {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  const ContentfulData = allfiles1.allContentfulModalCards.edges
  console.log(ContentfulData)

  return (
    <section className={className}>
      <Container>
        <Row>
          {ContentfulData.map(item => (
            <ModalCard
              title={item.node.cardTitle}
              key={item.node.id}
              text={item.node.cardText.cardText}
              imgSrc={item.node.cardImage.fluid}
              modalTitle={item.node.modalTitle}
              modalText1={item.node.modalText1.modalText1}
              modalText2={item.node.modalText2.modalText2}
              modalImages={item.node.modalImages}
            />
          ))}
        </Row>
      </Container>
    </section>
  )
}

export default styled(Modals)`
  border-top: 5px solid var(--maincolor);
  padding: 6rem 0;

  text-align: center;
  margin: 0 auto;

  /* @media (min-width: 800px) {
    .modal-image-wrapper {
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      grid-template-rows: repeat(8, 5vw);
      grid-gap: 15px;
    }
  } */
`
